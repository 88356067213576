import Color from "color-js";
import {
	colors,
	colorsDarkTheme,
	boxShadowsDarkMode,
	headingTypes,
	buttonSizes,
	boxShadows,
	borderRadiuses,
} from "./declares";

// convert all colors to hex format

const preparedColors = Object.fromEntries(
	Object.entries(colorsDarkTheme).map(([key, value]) => [
		key,
		Color(value).toString(),
	])
);

const preparedColorsDefaults = Object.fromEntries(
	Object.entries(colors).map(([key, value]) => [key, Color(value).toString()])
);

// // create mixins of box shadows
const boxShadowPrepared = {
	minimal: boxShadows.minimal.join(", "),
	panel: boxShadows.panel.join(", "),
	buttonWithText: boxShadows.buttonWithText.join(", "),
};

const borderRadiusPrepared = {
	minimal: `${borderRadiuses.minimal}rem`,
	panel: `${borderRadiuses.panel}rem`,
	element: `${borderRadiuses.element}rem`,
	card: `${borderRadiuses.card}rem`,
	landing: `${borderRadiuses.landing}rem`,
	progressBarLanding: `${borderRadiuses.progressBarLanding}rem`,
	progressBar: `${borderRadiuses.progressBar}rem`,
};

const SHADE_VALUE = 0.045;

function lighten(value, shade = 1) {
	const color = value instanceof Color ? value : Color(value);
	return color.lightenByAmount(shade * SHADE_VALUE).toString();
}

function darken(value, shade = 1) {
	const color = value instanceof Color ? value : Color(value);
	return color.darkenByAmount(shade * SHADE_VALUE).toString();
}

function isDark(value) {
	const color = value instanceof Color ? value : Color(value);
	return color.getLightness() < 0.5;
}

function createBoxShadow(levels) {
	const shadows = levels.map((name) =>
		!boxShadowsDarkMode[name] ? [name] : boxShadowsDarkMode[name]
	);
	return [].concat(...shadows).join(", ");
}

const utils = {
	lighten,
	darken,
	isDark,
	createBoxShadow,
};

const theme = {
	utils,
	colors: preparedColors,
	colorsDefaults: preparedColorsDefaults,
	headings: headingTypes,
	buttons: buttonSizes,
	boxShadows: boxShadowPrepared,
	borderRadius: borderRadiusPrepared,
	isDarkMode: true,
};

export default theme;
